import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  props: {
    directSystemId: String,
    invoiceId: String,
    selectStore: Array,
  },
  data() {
    return {
      isCalculateHeight: false,
      isRowClickCheckBox: false,
    };
  },
  created() {
    this.getConfigList('kms_tenantrydirect_store');
  },
  watch: {
    directSystemId(v) {
      this.getList();
    },
    invoiceId(v) {
      this.getList();
    },
  },
  methods: {
    beforeGetList() {
      if (!this.directSystemId || !this.invoiceId) {
        return false;
      }
      console.log(this.directSystemId, this.invoiceId);
      return true;
    },
    checkBoxMethod({ row }) {
      return !this.selectStore.includes(row.id);
    },
  },
};
