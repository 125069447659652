<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form'; // 手动抓单表单
import FormDetail from './form/components/form_detail.vue'; // 详情表单

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
    FormDetail,
  },
  data() {
    return {
      flag: false,
    };
  },
  async created() {
    await this.getConfigList('return_order_list');
  },
  methods: {
    // beforeGetList() {
    //   const { searchFormData } = this;
    //   let status = false;
    //   Object.keys(searchFormData).forEach((e) => {
    //     if (searchFormData[e] !== '') {
    //       status = true;
    //     }
    //   });
    //   return status;
    // },
    modalClick({ val, row }) {
      this.formName = '';
      this.modalName = '';
      if (val.code === 'manual_order_grabbing') {
        // 手动抓单
        this.formName = 'Form';
        this.formConfig = {
          type: val.code,
          id: row.id,
          row,
        };
        this.modalConfig.title = '手动抓单';
        this.openFull();
      } else if (val.code === 'view') {
        // 详情
        this.formName = 'FormDetail';
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '订单详情';
        this.openFull();
      } else if (val.code === 'manual_match') {
        // 手动匹配
        request.post('/kms/kmsreturnform/manualTrans', this.selection, { headers: { functionCode: 'return_order_list' } }).then((res) => {
          this.$message.success('操作成功');
          this.getList();
        });
      }
    },
  },
};
</script>
